import Link from "components/common/link";
import { Button, Box } from "@mui/material";
import * as React from "react";
import { FourOhFourContentContainer } from "../styles";
import { FourOhFourContentProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const FourOhFourContent: React.FC<FourOhFourContentProps> = ({
  cta,
  title,
}) => {
  return (
    <FourOhFourContentContainer className="four-oh-four-content">
      <Box className="title-container">
        <PrismicRichText field={title} />
      </Box>
      <Button
        className="four-oh-four-button"
        component={Link}
        to="/"
        variant="contained"
      >
        <PrismicRichText field={cta} />
      </Button>
    </FourOhFourContentContainer>
  );
};

export default FourOhFourContent;
