import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FourOhFourPageContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.four-oh-four-page-container {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_grey.backgroundSecondary};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
    position: relative;
  }
`;
