import React from "react";
import FourOhFour from "../components/pages/404";

interface FourOhFourProps {}

const FourOhFourPage: React.FC<FourOhFourProps> = () => {
  return <FourOhFour/>;
};

export default FourOhFourPage;
