import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FourOhFourContentContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.four-oh-four-content {
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(20)};
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(25, 4, 0, 4)};
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(5)};

      padding: ${({ theme: { spacing } }: ThemeType) => spacing(10, 4, 0, 4)};
    }
    & > .title-container {
      & > h1 {
        color: #000;
        font-family: "utopia-std-display";
        font-size: 54px;
        font-weight: 400;
        line-height: 1.16;
        text-align: center;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 52px;
          line-height: 1.08;
        }
      }
    }

    & > .four-oh-four-button {
      display: inline-block;
      font-size: 18px;
      text-transform: none;
      box-shadow: none;
      width: 200px;
      height: 48px;
      padding: 0;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
  }
`;

export const FourOhFourBackgroundContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.four-oh-four-background {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    & > .four-oh-four-background-image {
      width: 100%;
      margin: 0 auto;
      height: 50%;
      bottom: -450px;
      position: absolute;
      transform: translateY(20%);
      & > * {
        max-width: none !important;
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        transform: none;
        bottom: 0;
      }
    }

    & > .four-oh-four-woman {
      position: absolute;
      bottom: -120px;
      left: 0;
      z-index: 1;
      width: 310px;
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        bottom: 10%;
        left: 0;
        width: 130px;
      }
    }
  }
`;
