import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { FourOhFourBackgroundContainer } from "../styles";
import { FourOhFourBackgroundProps } from "../types";

const FourOhFourBackground: React.FC<FourOhFourBackgroundProps> = ({
  image,
  topImage,
}) => {
  return (
    <FourOhFourBackgroundContainer className="four-oh-four-background">
      <GatsbyImage
        className="four-oh-four-background-image"
        alt=""
        image={image}
      />
      <GatsbyImage alt="" className="four-oh-four-woman" image={topImage} />
    </FourOhFourBackgroundContainer>
  );
};

export default FourOhFourBackground;
