import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Seo from "components/common/seo";
import FourOhFourBackground from "./components/background";
import FourOhFourContent from "./components/content";
import { FourOhFourPageContainer } from "./styles";
import { FourOhFourProps } from "./types";
import useMediaQuery from "@mui/material/useMediaQuery";

const FourOhFour: React.FC<FourOhFourProps> = () => {
  const mobileView = useMediaQuery("(max-width: 719px)");
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismic404Page {
        nodes {
          data {
            title {
              richText
            }
            cta_text {
              richText
            }
            background_image {
              gatsbyImageData(layout: FIXED)
            }
            background_image_mobile {
              gatsbyImageData
            }
            background_top_image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const document = data.pageData.nodes[0].data;

  return (
    <>
      <Seo />

      <FourOhFourPageContainer className="four-oh-four-page-container">
        <FourOhFourContent
          cta={document.cta_text.richText}
          title={document.title.richText}
        />

        <FourOhFourBackground
          image={
            mobileView
              ? document.background_image_mobile.gatsbyImageData
              : document.background_image.gatsbyImageData
          }
          topImage={document.background_top_image.gatsbyImageData}
        />
      </FourOhFourPageContainer>
    </>
  );
};

export default FourOhFour;
